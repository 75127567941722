import React     from "react";
import PropTypes from "prop-types";

const FooterLikes = props => (
	<div className={'grid-2__tile__type-video__footer-likes' + (props.likedByGuest ? ' -liked-by-guest' : '')}>
		<div className={'grid-2__tile__type-video__footer-likes-button'}>
			<i className={'icon -icon-like-full'}></i>
		</div>
		<div className={'grid-2__tile__type-video__footer-likes-count'}>
			{props.count}
		</div>
	</div>
);

FooterLikes.propTypes = {
	count:        PropTypes.number.isRequired,
	likedByGuest: PropTypes.bool,
};

FooterLikes.defaultProps = {
	likedByGuest: false,
};

export default FooterLikes;