import React                                                   from 'react';
import Flux                                                    from '../../../flux/Flux';
import PropTypes                                               from 'prop-types';
import ReloadHelper                                            from '../../../utils/ReloadHelper';
import {COLOR_TYPE_CALL_TO_ACTION}                             from "../../SimpleElements/VXButton/ButtonHelper";
import {VXButtonConfig, VXButtonLineConfig, VXButtonLineStyle} from "../../SimpleElements/VXButton/VXButtonConfig";
import VXButton                                                from "../../SimpleElements/VXButton/VXButton";
import STYLES                                                  from "../../SimpleElements/VXButton/EnumVXButton";
import Translations                                            from "../../../utils/Translations";
import GuestAccess                                             from "../../../utils/GuestAccess";
import {formatCurrency}                                        from '../../../utils/CommonUtils';
import GuestStore                                              from "../../../flux/stores/GuestStore";

class VideoTileBuyButton extends React.Component {

	constructor(props) {
		super(props);

		this.buyButtonRef = null;

		this.onBuyClick  = this.onBuyClick.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);
	}

	onLinkClick(e) {
		ReloadHelper.reload(this.props.data.linkVX, e);
	}

	onBuyClick(e) {
		const data = this.props.data;

		e.preventDefault();
		e.stopPropagation();

		GuestAccess.onEnoughMoneyForShop(
			data.model.id,
			data.price.value,
			data.ageRating >= 18 && !Flux.Guest.hasAvs(),
			data.vip && !Flux.Guest.isVip(),
			() => {
				this.buyButtonRef.startTransition();

				const clb = (actorId, changedAlbumId) => {
					if (changedAlbumId === data.id) {
						Flux.Gallery.removeGalleryChangeListener(clb);

						const config   = new VXButtonConfig();
						config.color   = COLOR_TYPE_CALL_TO_ACTION;
						config.line1   = [new VXButtonLineConfig('Video')];
						config.line2   = [new VXButtonLineConfig(Translations.get('WatchNow'))];
						config.icon    = 'vxbutton-icon-play-rounded-line';
						config.onClick = this.onLinkClick;

						this.buyButtonRef.endTransition(config);
					}
				};

				Flux.Gallery.addGalleryChangeListener(clb);
				Flux.Gallery.buyAlbum(data.id);
			},
			undefined,
			data.price.currency === Flux.Constants.Currency.VOU
		);
	}

	render() {
		const props = this.props;
		const data  = props.data;

		let line1;
		let line2;
		let icon;
		let onClick;
		let animateSuccess;

		if (data.price.value > 0 && GuestStore.isLoggedIn()) {
			line1 = [new VXButtonLineConfig(Translations.get('Buy'), new VXButtonLineStyle([STYLES.UPPERCASE]))];
			if (data.price.currency === Flux.Constants.Currency.VOU) {

				line2          = [new VXButtonLineConfig(formatCurrency(data.price.value, null, data.price.currency))];
				icon           = "-icon-voucher-icon";
				onClick        = this.onBuyClick;
				animateSuccess = true;
			} else {
				if (data.price.value !== data.basePrice.value) {
					line2 = [
						new VXButtonLineConfig(formatCurrency(data.basePrice.value, null, data.basePrice.currency), new VXButtonLineStyle([STYLES.LINETHROUGH, STYLES.COLORS.MOOD3])),
						new VXButtonLineConfig(formatCurrency(data.price.value, null, data.price.currency)),
					];
				} else {
					line2 = [new VXButtonLineConfig(formatCurrency(data.price.value, null, data.price.currency))];
				}

				icon           = "vxbutton-icon-cart-full";
				onClick        = this.onBuyClick;
				animateSuccess = true;
			}

		} else if (data.guestInfo && data.guestInfo.bought) {
			line1          = [new VXButtonLineConfig(Translations.get('Video'), new VXButtonLineStyle([STYLES.UPPERCASE]))];
			line2          = [new VXButtonLineConfig(Translations.get('WatchNow'))];
			icon           = "vxbutton-icon-play-rounded-line";
			onClick        = this.onLinkClick;
			animateSuccess = true;
		} else {
			line1          = [new VXButtonLineConfig("Video"), new VXButtonLineStyle([STYLES.UPPERCASE])];
			line2          = [new VXButtonLineConfig(Translations.get('BuyNow'))];
			icon           = "vxbutton-icon-cart-full";
			onClick        = this.onBuyClick;
			animateSuccess = false;
		}

		return (

			<VXButton
				color={COLOR_TYPE_CALL_TO_ACTION}
				line1={line1}
				line2={line2}
				icon={icon}
				ref={ref => (this.buyButtonRef = ref)}
				onClick={onClick}
				animateSuccess={animateSuccess}
			/>
		);
	}
}

VideoTileBuyButton.propTypes = {
	data: PropTypes.object.isRequired, // VXQL video type
};


export default VideoTileBuyButton;