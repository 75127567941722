import React                   from 'react';
import PropTypes               from 'prop-types';
import Flux from "../../../../../flux/Flux";

const Types = Flux.Constants.Grid2.Ribbons;

const TYPE_CLASSNAME_MAPPING = {
	[Types.TRENDING]: '-trending',
};

const Ribbon = props => (
	<div className={'grid-2__tile__ribbons__ribbon ' + getAdditionalClassNameByType(props.type)}>
	</div>
);

function getAdditionalClassNameByType(type) {
	return TYPE_CLASSNAME_MAPPING[type] || '';
}

Ribbon.propTypes = {
	type: PropTypes.node,
};

export default Ribbon;
