import React from "react";
import PropTypes from "prop-types";
import Ribbons from "./Base/Ribbons/Ribbons";
import Ribbon from "./Base/Ribbons/Ribbon";
import Flux from "../../../flux/Flux";

// valid ribbon types for videos
const Types = [
	Flux.Constants.Grid2.Ribbons.TRENDING,
];

const VideoTileRibbon = props => {
	if (!props.config) {
		return null;
	}


	return (
		<Ribbons>
			<Ribbon type={props.config.type} />
		</Ribbons>
	);
};

export const VideoTileRibbonConfigType = PropTypes.shape({
	type: PropTypes.oneOf(Types),
});


VideoTileRibbon.propTypes = {
	config: VideoTileRibbonConfigType,
};

VideoTileRibbon.defaultProps = {
	config: null,
};

export default VideoTileRibbon;