import React                   from 'react';
import PropTypes               from 'prop-types';

const Ribbons = props => (
	<div className={'grid-2__tile__ribbons'}>
		{props.children}
	</div>
);

Ribbons.propTypes = {
	children: PropTypes.node,
};

export default Ribbons;
